@import url('https://fonts.googleapis.com/css2?family=ESCOREDREAM&display=swap');

@font-face {
    font-family: 'S-CoreDream-5Light';
    src: url('https://cdn.jsdelivr.net/gh/projectnoonnu/noonfonts_six@1.2/S-CoreDream-3Light.woff') format('woff');
    font-weight: normal;
    font-style: normal;
}

body {
  font-family: 'S-CoreDream-5Light' !important;
}